<template>
    <div class="left_menu">
        <!--left_menu PC버전-->
        <p class="menu_title">NiNiPlatformPatners</p>
        <ul>
            <li class="sub-menu">
                <router-link :to="`/${$i18n.locale}/dashboard`" ><img src="/assets/img/logo_h.png" alt="" class="menu_icon"  >DashBoard</router-link>
            </li>
            <li class="sub-menu" v-for="(item, index) in menu" :key="'depth01'+index">
                <a href="javascript:void(0)">
                    <img :src="item.img" alt="" class="menu_icon">{{item.title}}
                    <img src="/assets/img/more2.png" alt="" class="more">
                </a>
                <ul class="tab_menu" style="display: none;">
                    <li class="tab_menu2" v-for="(child, index) in item.children" :key="'depth02'+index">
                        <router-link :to="`/${$i18n.locale}${child.url}`" ><span class="icon_01"></span>{{child.title}}</router-link>
                    </li>
                </ul>
            </li>
            <li class="sub-menu">
                <a href="javascript:void(0)" @click="logout()"> <img src="/assets/img/logout.png" alt="" class="menu_icon"  >{{$t('sidebar.99')}}</a>
            </li>
        </ul>
    </div>
    <!--left_menu-->
</template>

<script>
	export default {
        data(){
            return{
                
                menu:[
                        {
                            title: this.$t('sidebar.1'),
                            img: '/assets/img/icon01.png',
                            children: [
                                {
                                    title: this.$t('sidebar.2'),
                                    url:'/notice'
                                },
                                {
                                    title: this.$t('sidebar.3'),
                                    url:'/shared'
                                },
                                {
                                    title: this.$t('sidebar.4'),
                                    url:'/inquiry'
                                }
                            ]
                        },
                        // {
                        //     title: "조직도",
                        //     img: '/assets/img/icon02.png',
                        //     children: [
                        //         {
                        //             title: "추천인",
                        //             url:'/ReferralTree'
                        //         },
                        //         {
                        //             title: "후원인",
                        //             url:'/SupportTree'
                        //         },
                        //     ]
                        // },
                        // {
                        //     title: "매출",
                        //     img: '/assets/img/icon03.png',
                        //     children: [
                        //         {
                        //             title:"패키지",
                        //             url:'/Membership'
                        //         },
                        //     ]
                        // },
                        {
                            title: "거래",
                            img: '/assets/img/icon04.png',
                            children: [
                                {
                                    title: "입금" ,
                                    url:'/Deposit'
                                },
                                {
                                    title: "출금",
                                    url:'/Withdraw'
                                },
                                {
                                    title:this.$t('sidebar.14'),
                                    url:'/Exchange'
                                }
                            ]
                        },
                        // {
                        //     title: "내역",
                        //     img: '/assets/img/icon05.png',
                        //     children: [
                        //         {
                        //             title: "구매 내역",
                        //             url:'/SalesList'
                        //         },
                        //         {
                        //             title: "자산 내역",
                        //             url:'/AssestHistory'
                        //         },
                        //         {
                        //             title: "추천수당",
                        //             url:'/ReferralHistory'
                        //         },
                        //         {
                        //             title: "후원 수당",
                        //             url:'/BonusHistory'
                        //         },
                        //         {
                        //             title: "매칭 수당",
                        //             url:'/BonusHistory'
                        //         },
                        //         {
                        //             title: "직급 수당",
                        //             url:'/BonusHistory'
                        //         },
                        //         {
                        //             title: "멤버쉽 수당",
                        //             url:'/BonusHistory'
                        //         },
                        //         {
                        //             title: "롤업 수당",
                        //             url:'/BonusHistory'
                        //         },
                        //     ]
                        // },
                        {
                            title: "프로필",
                            img: '/assets/img/icon06.png',
                            children: [
                                
                                {
                                    title: "내정보",
                                    url:'/profile'
                                },
                                {
                                    title: "비밀번호 변경",
                                    url:'/changePw'
                                },
                                {
                                    title: "핀번호 변경",
                                    url:'/changePin'
                                },
                                {
                                    title: "OTP 정보",
                                    url:'/profile'
                                },
                            ]
                        },
                ]
            }
        },
		mounted(){
			$('.sub-menu ul').hide();
			$(".sub-menu a").click(function () {
			$(this).parent(".sub-menu").children("ul").slideToggle("100");
			$(this).find(".right").toggleClass("fa-caret-up fa-caret-down");
			});
        },
        methods:{
            
            logout : function(){
                this.$store.dispatch('SETLOGOUT').then(
                    ()=>{
                        this.$router.push({path:'/'+this.$i18n.locale+'/landing'});
                    }
                )
            }
        },
	}
</script>

<style>

</style>