<template>
  <div id="app" :class="theme">
  <!-- <div id="app" :class="'theme03'"> git test -->
    <router-view/>
  </div>
</template>

<script>
export default {
  data() {
    return {
      theme: this.$store.state.theme
    }
  },
  computed: {
    getN () {
        return this.$store.state.theme
    },
},
watch: {
    getN (val, oldVal) {
        this.theme = val
    }
},    
}
</script>

<style lang="scss">
@import 'assets/css/bootstrap.css';
@import 'assets/css/custom-bootstrap.scss';

.swal2-styled.swal2-confirm{
  background-color: #9545ff;
}
.bg-ripple-container+img{
  position: absolute;
  left: 0px;
  top: -2px;
}

.theme01 .card{
  background-color: #424350;
}
.theme03 .card{
  background-color: #fff;
}
</style>
