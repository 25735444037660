import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    login: true,
    token : '',
    id : '',
    name :'',
    theme: 'theme01'
  },
  plugins: [createPersistedState()],
  mutations: {
    SETMEMBER(state, mem_info) {
      state.token = mem_info.token;
      state.id = mem_info.id;
      state.name = mem_info.name;
    },
    SETLOGIN(state){
      state.login = true;
    },
    SETLOGOUT(state){
      state.login = false;
      state.token = '';
      state.id = '';
      state.name = '';
    },
    SETTHEMA(state, mem_info) {
      state.theme = mem_info.type;
    },
  },
  actions: {
    SETMEMBER ({commit}, {mem_info}) {
      commit('SETMEMBER', mem_info);
      commit('SETLOGIN');

    },
    SETLOGOUT ({commit}) {
      commit('SETMEMBER', '');
      commit('SETLOGOUT');
    },
    SETTHEMA ({commit}, {mem_info}) {
      commit('SETTHEMA', mem_info);
    }
  }
})
